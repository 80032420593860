import React from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import QRCode from "react-qr-code";

const apkVersion = "0.3.18";

const ModalViewQrLinkMessageHub = ({ active, setActive }) => {
  useGlobalScrollLock(active);
  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal26"
      onClick={() => setActive(false)}
    >
      <div
        className="dialog"
        onClick={(e) => e.stopPropagation(e)}
        style={{
          backgroundColor: "#fff",
        }}
      >
        <div className="dialog__head">
          <div
            className="dialog__head-title"
            style={{
              color: "#000",
            }}
          >
            Скачать автоматику (apk) v{apkVersion}
          </div>
          <div
            className="dialog__head-close"
            id="modal16Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          {apkVersion && (
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={
                "https://ros.fra1.digitaloceanspaces.com/media/app-release-v" +
                apkVersion +
                ".apk"
              }
              viewBox={`0 0 256 256`}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalViewQrLinkMessageHub;
