import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import {
  formatFinance,
  getShortRequisiteData,
  getLkAlias,
} from "../common/utils";
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";

import useDayjsFormat from "../hooks/useDayjsFormat";

import ModalViewAppeal from "./modal/ModalViewAppeal";

const TradeAppeals = (props) => {
  const [pageAppeals, setPageAppeals] = useState(1);
  const [modalActive, setModalActive] = useState(false);
  const [appealData, setAppealData] = useState({});
  const [optionValue, setOptionValue] = useState(10);
  const [valueSearchState, setValueSearchState] = useState("");
  const [optionValueStatus, setOptionValueStatus] = useState("-9");

  const params = useParams();

  const appealStatus = ["Ожидает", "Принята", "Отклонена"];

  const getAppealStatus = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < appealStatus.length) {
      return appealStatus[index];
    } else {
      return "В архиве";
    }
  };

  const appealStyles = [
    "btn-innactive btn-xs btn-warning",
    "btn-innactive btn-xs btn-innactive_success",
    "btn-innactive btn-xs btn-innactive_error",
  ];

  const getAppealStyle = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < appealStyles.length) {
      return appealStyles[index];
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: `/trader/appeals`,
      method: "POST",
    },
    axiosConfig
  );

  const [
    { data: dataAppeal, loading: loadingAppeal },
    executePostAppealView,
    cancelRequestAppealView,
  ] = useAxios(
    {
      url: `/trader/appeals/view`,
      method: "POST",
    },
    axiosConfig
  );

  const handleViewAppeal = (index) => {
    setAppealData(data?.appeals[index]);
    setModalActive(true);
  };

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          lk_id: valueSearchState,
          status: optionValueStatus,
          page: pageAppeals,
          count: optionValue,
        },
      }).catch((error) => {});
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [
    pageAppeals,
    optionValue,
    modalActive,
    optionValueStatus,
    valueSearchState,
  ]);

  let pageCountQuery = Math.ceil(data?.count_appeals / optionValue);

  useEffect(() => {
    if (Object.keys(params).length >= 1 && params?.itemId) {
      // console.log("params", params);
      executePostAppealView({
        data: {
          lk_id: valueSearchState,
          status: optionValueStatus,
          count: optionValue,
        },
      }).catch((error) => {});
    } else {
      // setModalActive(false);
    }
  }, [params]);

  const options = [
    { id: 1, value: 10 },
    { id: 2, value: 25 },
    { id: 3, value: 50 },
    { id: 4, value: 100 },
    { id: 5, value: 150 },
  ];
  const optionsStatus = [
    { id: 1, value: -9, name: "Любой" },
    { id: 2, value: 0, name: "Ожидает" },
    { id: 3, value: 1, name: "Принята" },
    { id: 4, value: 2, name: "Отклонена" },
    { id: 5, value: -1, name: "В архиве" },
  ];
  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
    setPageAppeals(1);
  };
  const handleChangeStatus = (e) => {
    setOptionValueStatus(e.target.value);
    setPageAppeals(1);
  };
  const handleRefetch = () => {
    if (!modalActive) {
      executePost({
        data: {
          lk_id: valueSearchState,
          status: optionValueStatus,
          page: pageAppeals,
          count: optionValue,
        },
      }).catch((error) => {});
    }
  };

  /*
  if (loading)
    return (
      <div className="project-details__tab project-details__tab_active">
        <div className="project-details__table mt-30">
          <div style={{ display: "flex" }}>
            <Spinner />
          </div>
        </div>
      </div>
    );
    */

  return (
    <>
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Апелляции
        </div>
      </div>
      <div className="project-details__table-filter __trader">
        <input
          value={valueSearchState}
          className="input"
          type="text"
          placeholder="Поиск"
          onChange={(e) => {
            setValueSearchState(e.target.value);
          }}
        />
        <div
          className="d-flex gap-20"
          style={{ alignItems: "flex-start", flexDirection: "column" }}
        >
          <span>Статус</span>
          <select onChange={handleChangeStatus}>
            {optionsStatus.map((item) => (
              <option key={item.id} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div
          className="d-flex gap-20"
          style={{ alignItems: "flex-start", flexDirection: "column" }}
        >
          <span>Количество</span>
          <select onChange={handleChangeOption}>
            {options.map((item) => (
              <option key={item.id} value={item.value}>
                {item.value}
              </option>
            ))}
          </select>
        </div>
        <button className="btn btn-success" onClick={handleRefetch}>
          Обновить
        </button>
      </div>
      {loading ? (
        <>
          <div className="project-details__tab project-details__tab_active">
            <div className="project-details__table mt-30">
              <div style={{ display: "flex" }}>
                <Spinner />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              alignContent: "center",
            }}
          >
            {
              <AnimatedBlock>
                <table className="fixedtable">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>ID ордера</th>
                      <th>Дата ордера</th>
                      <th>ЛК</th>
                      <th>Метод</th>
                      <th>Реквизиты</th>
                      <th>Сумма</th>
                      <th>Дата апелляции</th>
                      <th>Статус</th>
                      <th>Действия</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.appeals.map(
                      (
                        {
                          id,
                          bank,
                          id_alias,
                          payment_id,
                          trade_createdAt,
                          method_group,
                          note,
                          bank_profile_id,
                          payment_note,
                          amount_to_pay,
                          createdAt,
                          status,
                        },
                        index
                      ) => (
                        <tr key={id}>
                          <td data-label="ID">{id}</td>
                          <td data-label="ID ордера">{payment_id}</td>
                          <td data-label="Дата ордера">
                            {getFullTimeMsk(trade_createdAt)} <br />
                            {getFullDateMsk(trade_createdAt)}
                          </td>
                          <td data-label="ЛК">{getLkAlias(bank, id_alias)}</td>
                          <td data-label="Метод">{method_group}</td>
                          <td data-label="Реквизиты">
                            {getShortRequisiteData(payment_note)}
                          </td>
                          <td data-label="Сумма">
                            {formatFinance(amount_to_pay)} ₽
                          </td>
                          <td data-label="Дата апелляции">
                            {getFullTimeMsk(createdAt)} <br />
                            {getFullDateMsk(createdAt)}
                          </td>
                          <td data-label="Статус">
                            <span className={getAppealStyle(status)}>
                              {getAppealStatus(status)}
                            </span>
                          </td>
                          <td data-label="Действия">
                            <button
                              className="btn btn-xs btn-success btn_minimal"
                              onClick={() => {
                                handleViewAppeal(index);
                              }}
                            >
                              Подробно
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </AnimatedBlock>
            }
          </div>
          {
            <Pagination
              page={pageAppeals}
              setPage={setPageAppeals}
              pageCountQuery={pageCountQuery}
            />
          }
        </>
      )}
      <ModalViewAppeal
        active={modalActive}
        setActive={setModalActive}
        appealData={appealData}
      />
    </>
  );
};

export default TradeAppeals;
