import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { formatMaskPhoneNumber } from "../common/utils";
import useDayjsFormat from "../hooks/useDayjsFormat"; // getDeltaTime
import Pagination from "./Pagination";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Spinner from "./spinner/Spinner";
import ModalAddDeviceProfile from "./modal/ModalAddDeviceProfile";
import ModalAddBankProfile from "./modal/ModalAddBankProfile";
import ModalViewQrLinkDeviceProfile from "./modal/ModalViewQrLinkDeviceProfile";
import ModalViewQrLinkMessageHub from "./modal/ModalViewQrLinkMessageHub";

import { AddShopPlusIcon, AcceptIcon, QRCodeIcon, EditIcon } from "./Icons";

const TradeDeviceProfiles = () => {
  const [modalActive, setModalActive] = useState(false);
  const [modal2Active, setModal2Active] = useState(false);
  const [modal3Active, setModal3Active] = useState(false);
  const [modal4Active, setModal4Active] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const [deviceIdAlias, setDeviceIdAlias] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  const [pageDeviceProfiles, setPageDeviceProfiles] = useState(1);
  const count = 10;
  const deviceprofileStatus = ["Отключен", "Доступен"];

  const { isDeltaMinute, getFullTimeMsk, getFullDateMsk } = useDayjsFormat();

  const getDeviceProfileStatus = (heartbeat) => {
    return isDeltaMinute(heartbeat)
      ? deviceprofileStatus[0]
      : deviceprofileStatus[1];
  };

  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: "/trader/deviceprofiles",
      method: "POST",
    },
    axiosConfig
  );

  const [{ data: tokenData, loading: tokenLoading }, executePostToken] =
    useAxios(
      {
        url: "/trader/deviceprofiles/gettoken",
        method: "POST",
      },
      axiosConfig
    );

  const handleModalActivity = (active = true) => {
    setModalActive(active);
  };

  const handleModal4Activity = (active = true) => {
    setModal4Active(active);
  };

  const handleModal2Activity = (active = true) => {
    setModal2Active(active);
    if (active) {
    } else {
      setDeviceId(null);
      setDeviceIdAlias(null);
      setDeviceToken(null);
    }
  };

  const handleModal3Activity = (active = true, id) => {
    setDeviceId(id);
    setModal3Active(active);
  };

  const hendleGetToken = (id, id_alias) => {
    executePostToken({
      data: {
        id: id,
      },
    })
      .then((data) => {
        setDeviceId(id);
        setDeviceIdAlias(id_alias);
        setDeviceToken(data?.data?.deviceProfileToken);
        setModal2Active(true);
      })
      .catch((error) => {
        // console.log("executePostToken", error);
      });
  };

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          page: pageDeviceProfiles,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageDeviceProfiles, count, modalActive]);

  let pageCountQuery = Math.ceil(data?.countDeviceProfiles / count);

  if (loading)
    return (
      <div style={{ display: "flex" }}>
        <Spinner />
      </div>
    );

  return (
    <>
      <ModalAddDeviceProfile
        active={modalActive}
        setActive={handleModalActivity}
      />
      <ModalAddBankProfile
        active={modal3Active}
        setActive={handleModal3Activity}
        Device={deviceId}
      />
      <ModalViewQrLinkDeviceProfile
        active={modal2Active}
        deviceToken={deviceToken}
        deviceId={deviceId}
        deviceIdAlias={deviceIdAlias}
        setActive={handleModal2Activity}
      />
      <ModalViewQrLinkMessageHub
        active={modal4Active}
        setActive={handleModal4Activity}
      />
      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Устройства
        </div>
        <button
          className="btn btn_add"
          id="add-project"
          onClick={handleModalActivity}
        >
          <AddShopPlusIcon />
        </button>
        <button
          className="btn btn-xs btn_minimal"
          onClick={() => {
            handleModal4Activity();
          }}
        >
          <QRCodeIcon />
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Телефон</th>
                  <th>Пинг</th>
                  <th>Доступность</th>
                  <th>Действия</th>
                </tr>
              </thead>
              <tbody>
                {data?.deviceProfiles.map(
                  (
                    { id, id_alias, phonenumber, heartbeat, createdAt },
                    index
                  ) => (
                    <tr key={id}>
                      <td data-label="ID">{id_alias}</td>
                      <td data-label="Телефон">
                        {formatMaskPhoneNumber(phonenumber)}
                      </td>
                      <td data-label="Пинг">
                        {getFullTimeMsk(heartbeat)} <br />
                        {getFullDateMsk(heartbeat)}
                      </td>
                      <td data-label="Доступность">
                        {getDeviceProfileStatus(heartbeat)}
                      </td>
                      <td data-label="Действия">
                        <button
                          className="btn btn-xs btn-success btn_minimal"
                          onClick={() => {
                            hendleGetToken(id, id_alias);
                          }}
                        >
                          <QRCodeIcon />
                        </button>
                        <button
                          className="btn btn-xs btn-success btn_minimal"
                          onClick={() => {
                            handleModal3Activity(true, id);
                          }}
                        >
                          <AddShopPlusIcon />
                        </button>
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageDeviceProfiles}
          setPage={setPageDeviceProfiles}
          pageCountQuery={pageCountQuery}
        />
      }
    </>
  );
};

export default TradeDeviceProfiles;
