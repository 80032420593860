const TRADE_MODE =
  (process.env.REACT_APP_TRADE_MODE || "false") === "true" ? true : false;
const API_URL = process.env.REACT_APP_API_URL || "https://api.rosplat.cash/";
const RECAPTCHA_PUBLIC_KEY = process.env.REACT_APP_RECAPTCHA_KEY_DEV || "";
const API_DOCS_URL = process.env.REACT_APP_API_DOCS || "";
const SUPPORT_TELEGRAM_URL = process.env.REACT_APP_SUPPORT_TELEGRAM || "";
const CDN_PUBLIC_URL =
  process.env.REACT_APP_CDN_PUBLIC_URL ||
  "https://ros.fra1.digitaloceanspaces.com/";

  const generalBankList = {
    100000000004: "Т-Банк",
    100000000111: "Сбербанк",
    100000000012: "Росбанк",
    100000000007: "Райффайзен",
    100000000008: "АЛЬФА-БАНК",
    100000000026: "Уралсиб",
    100000000041: "БКС Банк",
  };
  const aliasBankList = {
    100000000004: "Т",
    100000000111: "С",
    100000000012: "Р",
    100000000007: "Р",
    100000000008: "А",
    100000000026: "У",
    100000000041: "Б",
  };

const trafficDetailsScore = {
  1: "Любой",
  2: "Только вторичный",
  3: "Только VIP",
};

export {
  TRADE_MODE,
  RECAPTCHA_PUBLIC_KEY,
  API_URL,
  API_DOCS_URL,
  SUPPORT_TELEGRAM_URL,
  CDN_PUBLIC_URL,
  generalBankList,
  trafficDetailsScore,
  aliasBankList,
};
