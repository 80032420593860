import React, { useState, useEffect } from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { $authHost, axiosConfig } from "../../common/api";
import { isPhoneNumber } from "../../common/utils";
import { generalBankList, trafficDetailsScore } from "../../common/constants";

const ModalAddBankProfile = ({
  active,
  setActive,
  Device,
  isEdit = false,
  editDitails = {},
}) => {
  const [id, setId] = useState("");
  const [fio, setFio] = useState("");
  const [bank, setBank] = useState("");
  const [tier, setTier] = useState("");
  const [minAmount, setMinAmount] = useState(100);
  const [maxAmount, setMaxAmount] = useState(1000);
  const [totalAmount, setTotalAmount] = useState(10000);
  const [usedTotalAmount, setUsedTotalAmount] = useState(0);
  const [sbpAvailable, setSbpAvailable] = useState(1);
  const [available, setAvailable] = useState(1);
  const [cardAvailable, setCardAvailable] = useState(1);
  const [enablePush, setEnablePush] = useState(1);

  /*
     device_profile_id,
        fio,
        bank,
        min_amount, // 100
        max_amount, // 1000
        total_amount, // 10000
        used_total_amount, // 0
        sbp_available, // 1 or 0
        available, // 1 or 0
        enable_push,
        card_available
  */

  const getErrorBlock = (error) => {
    return error ? (
      <span
        style={{
          color: "red",
          position: "absolute",
          bottom: -25,
        }}
      >
        {error?.message}
      </span>
    ) : null;
  };

  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/bankprofiles/create",
      method: "POST",
    },
    axiosConfig
  );

  useEffect(() => {
    if (!active) {
      setId(0);
      setFio("");
      setBank("");
      setMinAmount(100);
      setMaxAmount(1000);
      setTotalAmount(5000000);
      setUsedTotalAmount(0);
      setSbpAvailable(1);
      setAvailable(1);
      setCardAvailable(1);
      setEnablePush(1);
      setTier(1);
    } else {
      if (isEdit) {
        setId(editDitails.id);
        setFio(editDitails.fio);
        setBank(editDitails.bank);
        setMinAmount(editDitails.min_amount);
        setMaxAmount(editDitails.max_amount);
        setTotalAmount(editDitails.total_amount);
        setUsedTotalAmount(editDitails.used_total_amount);
        setSbpAvailable(editDitails.sbp_available);
        setAvailable(editDitails.available);
        setCardAvailable(editDitails.card_available);
        setEnablePush(editDitails.enable_push);
        setTier(editDitails.tier || 1);
      }
    }
  }, [
    active,
    isEdit,
    editDitails,
    setId,
    setFio,
    setBank,
    setMinAmount,
    setMaxAmount,
    setTotalAmount,
    setUsedTotalAmount,
    setSbpAvailable,
    setAvailable,
    setCardAvailable,
    setEnablePush,
    setTier,
  ]);

  const submitModal = async () => {
    await executePost({
      data: {
        device_profile_id: Device,
        fio,
        bank,
        min_amount: minAmount,
        max_amount: maxAmount,
        total_amount: totalAmount,
        used_total_amount: usedTotalAmount,
        sbp_available: sbpAvailable,
        available,
        id,
        enable_push: enablePush,
        card_available: cardAvailable,
        tier: tier,
      },
    })
      .then((data) => {
        setActive(false);
        if (!isEdit) toast.success("Банк успешно добавлен!");
        else toast.success("Банк успешно изменен!");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal6"
      onClick={() => setActive(false)}
    >
      <div className="dialog" onClick={(e) => e.stopPropagation(e)}>
        <div className="dialog__head">
          <div className="dialog__head-title">
            Добавление банка на устройство
          </div>
          <div
            className="dialog__head-close"
            id="modal6Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <div className="custom-input custom-input_block">
            <label htmlFor="deviceprofile-phone">ФИО</label>
            <input
              value={fio}
              type="text"
              id="deviceprofile-phone"
              autoComplete="off"
              placeholder="Узбекистанец"
              onChange={(e) => setFio(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankrofile-bank">Банк</label>
            <select
              value={bank}
              id="bankrofile-bank"
              onChange={(e) => setBank(e.target.value)}
            >
              <option value="">Выберите банк</option>
              {Object.keys(generalBankList).map((key) => (
                <option key={key} value={key}>
                  {generalBankList[key]}
                </option>
              ))}
            </select>
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Мин платеж</label>
            <input
              value={minAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setMinAmount(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Макс платеж</label>
            <input
              value={maxAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setMaxAmount(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Максимум на карту</label>
            <input
              value={totalAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankprofile-minamount">Использовано на карте</label>
            <input
              value={usedTotalAmount}
              type="text"
              id="bankprofile-minamount"
              autoComplete="off"
              placeholder="100"
              onChange={(e) => setUsedTotalAmount(e.target.value)}
            />
          </div>

          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="bankprofile-sbpavailable"
                checked={sbpAvailable === 1 ? true : false}
                onChange={() => setSbpAvailable(sbpAvailable === 1 ? 0 : 1)}
              />
              <label htmlFor="bankprofile-sbpavailable">Включен сбп?</label>
            </div>
          </div>

          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="bankprofile-pushenable"
                checked={enablePush === 1 ? true : false}
                onChange={() => setEnablePush(enablePush === 1 ? 0 : 1)}
              />
              <label htmlFor="bankprofile-pushenable">
                Пуши включены ловим их?
              </label>
            </div>
          </div>

          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="bankprofile-cardenable"
                checked={cardAvailable === 1 ? true : false}
                onChange={() => setCardAvailable(cardAvailable === 1 ? 0 : 1)}
              />
              <label htmlFor="bankprofile-cardenable">
                Карты доступны на этом банке или только сбп?
              </label>
            </div>
          </div>

          <div className="custom-input custom-input_block">
            <label htmlFor="bankrofile-tier">Трафик</label>
            <select
              value={tier}
              id="bankrofile-tier"
              onChange={(e) => setTier(e.target.value)}
            >
              <option value="">Выберите качество трафика</option>
              {Object.keys(trafficDetailsScore).map((key) => (
                <option key={key} value={key}>
                  {trafficDetailsScore[key]}
                </option>
              ))}
            </select>
          </div>

          <div className="custom-input custom-input_block">
            <div className="custom-switch">
              <input
                type="checkbox"
                id="bankprofile-available"
                checked={available === 1 ? true : false}
                onChange={() => setAvailable(available === 1 ? 0 : 1)}
              />
              <label htmlFor="bankprofile-available">Доступность</label>
            </div>
          </div>

          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              {isEdit ? "Сохранить" : "Добавить"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalAddBankProfile;
